import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';
import pages from "../api/pages";

Vue.use(Router);
const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

let path = '/';

router.beforeEach((to, from, next) => {
  store.dispatch('setLoading', true)
  if (to.name !== from.name) {
    if (to.matched.some(record => !record.meta.public)) {
      if (store.getters.isLoggedIn) {
        next();
        return;
      }
      next("/");
    } else {
      if (to.name === "") {}
      next();
    }
  }
});

router.afterEach((to, from, next) => {
  setTimeout(offLoading, 1400);

})

function offLoading() {
  store.dispatch('setLoading', false)
}

export default router;
