import i18n from "@/plugins/vue-i18n";

const Rules = {
    nospace: v => (v || "").indexOf(" ") < 0 || i18n.t('rules.nospace'),
    required: v => !!v || i18n.t('rules.required'),
    date: v => /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/.test(v) || i18n.t('rules.date'),
    dateOfBirthMoreThan18: v => v.split("-").length === 3 && new Date().getFullYear() - v.split("-")[0] > 18 || i18n.t('rules.dateOfBirthMoreThan18'),
    idRequired: v => !!v?.id || i18n.t('rules.idRequired'),
    min: v => !!v && (v || "").length >= 8 || i18n.t('rules.min'),
    max: v => !!v && (v || "").length <= 20 || i18n.t('rules.max'),
    email: v => !!v && v.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null || i18n.t('rules.email'),
    cyrillic: v => v && !!v.match(/^[\u0400-\u04FF]+$/) || i18n.t('rules.cyrillic'),
    cyrillicAndSpace: v => v && !!v.match(/^[а-яА-ЯёЁ\s-]+$/) || i18n.t('rules.cyrillic'),
    latAndNum: v => v && !!v.match(/^[a-zA-Z0-9-_@]*$/) || i18n.t('rules.latAndNum'),
    latAndNumAndSpace: v => v && !!v.match(/^[a-zA-Z0-9-\s]*$/) || i18n.t('rules.latAndNum'),
    latLetters: v => v && !!v.match(/^[a-zA-Z]+$/) || i18n.t('rules.latLetters'),
    latLettersAndSpace: v => v && !!v.match(/^[a-zA-Z-\s]*$/) || i18n.t('rules.latLetters'),
    lat: v => v && !!v.match(/^[a-zA-Z]*$/) || i18n.t('rules.latLetters'),
    latAndCyr: v => v && !!v.match(/^[a-zA-ZА-ЯЁа-яё-]*$/) || i18n.t('rules.latAndCyr'),
    vehicleNumber: v => v && !!v.match(/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/) || i18n.t('rules.vehicleNumber'),
    vinNumber: v => !!v && (v || "").length === 17 || i18n.t('rules.vinNumber'),
    phone: v => v && /(\d{9})$/.test(v) || i18n.t('rules.phone'),
    numberWithPoint: v => /^[0-9]+(\.[0-9]+)?$/.test(v) || i18n.t('rules.numberWithPoint'),
    number: v => /^[0-9]+$/.test(v) || i18n.t('rules.number'),
    requiredArray: value => !!value && value.length > 0 || i18n.t('rules.requiredArray'),
    dateAfterToday: (date, message) => date.length === 10 && (new Date(date.split(".")[2] + "-" + date.split(".")[1] + "-" + date.split(".")[0]) > new Date() || new Date(date) === new Date())
        || (message || i18n.t('rules.dateAfterToday')),
    dateBeforeToday: (date, message) => date.length === 10 && (new Date(date.split(".")[2] + "-" + date.split(".")[1] + "-" + date.split(".")[0]) < new Date() || new Date(date) === new Date())
        || (message || i18n.t('rules.dateBeforeToday')),
    login: value => !!value || i18n.t('rules.login'),
    password: v => v && !!v.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/) || i18n.t('rules.password')
};

export default Rules