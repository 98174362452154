<template>
  <ul>
    <li id="a"></li>
    <li id="b"></li>
    <li id="c"></li>
    <li id="d"></li>
    <li id="e"></li>
    <li id="f"></li>
    <li id="g"></li>
    <li id="h"></li>
    <li id="i"></li>
  </ul>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style lang="sass">
body
  background: white

ul
  position: absolute
  left: 50%
  top: 50%
  transform: rotate(45deg) translate(-50%, -50%)

li
  list-style-type: none
  position: absolute
  top: 0px
  left: 0px
  width: 20px
  height: 20px
  background: #EE1D23
  border-radius: 50%

@mixin place($n, $t, $l)
  animation: $n 1s ease-in-out infinite
  top: $t
  left: $l

#a
  @include place(a, -40px, -40px)
#b
  @include place(b, -40px, 0px)
#c
  @include place(c, -40px, 40px)
#d
  @include place(d, 0px, -40px)
#e
  @include place(e, 0px, 0px)
#f
  @include place(f, 0px, 40px)
#g
  @include place(g, 40px, -40px)
#h
  @include place(h, 40px, 0px)
#i
  @include place(i, 40px, 40px)

@keyframes a
  50%
    top: 0px
    left: -40px
  100%
    top: 0px
    left: -40px

@keyframes b
  50%
    top: -40px
    left: -40px
  100%
    top: -40px
    left: -40px

@keyframes c
  50%
    top: -40px
    left: 0px
  100%
    top: -40px
    left: 0px

@keyframes d
  50%
    top: 40px
    left: -40px
  100%
    top: 40px
    left: -40px

@keyframes f
  50%
    top: -40px
    left: 40px
  100%
    top: -40px
    left: 40px

@keyframes g
  50%
    top: 40px
    left: 0px
  100%
    top: 40px
    left: 0px

@keyframes h
  50%
    top: 40px
    left: 40px
  100%
    top: 40px
    left: 40px

@keyframes i
  50%
    top: 0px
    left: 40px
  100%
    top: 0px
    left: 40px


</style>