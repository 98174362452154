import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import http from "./plugins/http";
import phoneCodes from "@/api/phoneCodes";
import rules from "@/api/rules";
import domain from "@/api/domain";
import { i18n } from './plugins/vue-i18n'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import "./plugins/vuetify";
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./components/components"
import "@/scss/style.scss";
import { hasFlag } from 'country-flag-icons'
import { countries } from 'country-flag-icons'

// const VueInputMask = require('vue-inputmask').default
//
// Vue.use(VueInputMask)

import HeroIcon from 'vue-heroicons'
import {
  altArrowDownOutline,
  transferHorizontalOutline,
  chatOutline,
  searchOutlined,
  messageConversationOutlined,
  winRarOutlined,
  dialogOutlined,
  smartphoneOutlined,
  uploadOutlined,
  userOutlined,
  signpostOutlined,
  billOutlined,
  dollarOutlined,
  penOutlined,
  printerOutlined,
  arrowLeftOutlined,
  suspensionOutlined,
  trashOutlined,
  arrowsStrokeOutlined,
  fuelOutlined,
  shieldStarOutlined,
  shieldUserOutlined,
  shieldUpOutlined,
  routingOutlined,
  plusOutlined,
  busOutlined,
  bedOutlined,
  cardOutlined,
  fileTextOutlined,

  squareTopUpBold,
  inboxOutBold,
  usersGroupTwoRoundedBold,
  penBold,
  walletBold,
  chartBold,
  passwordBold,
  trashBold,
  lockKeyholeUnlockedBold,
  widgetBold,
  qrCodeBold,
  addCircleBold,
  arrowRightDownBold,
  arrowRightUpBold,
  busBold,
  userBold,
  mapArrowRotateBold,
  radarBold,
  headPhonesBold,
  garageBold,
  globusBold,
  pointOnMapPerspectiveBold,
  cityBold,
  layerBold,
  threeSquaresBold,
  serverMinimalisticBold,
  crossBold,
  userHeartBold,
  rulerCrossPenBold,
  arrowSquareBold,
  userIdBold,
  devicesBold,
  suspensionBold,
  removeFileBold,
  fileBold,
  fileRightBold,
  altArrowRightOutlined,
  fileLoadBold,
  calendarOutlined,
  usersGroupTwoOutlined,
  closeCircleOutlined,
  minusOutlined,

} from './icons/icons'

HeroIcon.add([
  altArrowDownOutline,
  transferHorizontalOutline,
  chatOutline,
  searchOutlined,
  messageConversationOutlined,
  winRarOutlined,
  dialogOutlined,
  smartphoneOutlined,
  uploadOutlined,
  userOutlined,
  signpostOutlined,
  billOutlined,
  dollarOutlined,
  penOutlined,
  printerOutlined,
  arrowLeftOutlined,
  suspensionOutlined,
  trashOutlined,
  arrowsStrokeOutlined,
  altArrowRightOutlined,
  calendarOutlined,
  usersGroupTwoOutlined,
  closeCircleOutlined,
  minusOutlined,
  plusOutlined,
  busOutlined,
  bedOutlined,
  fuelOutlined,
  shieldStarOutlined,
  shieldUserOutlined,
  shieldUpOutlined,
  routingOutlined,
  cardOutlined,
  fileTextOutlined,

  squareTopUpBold,
  inboxOutBold,
  usersGroupTwoRoundedBold,
  penBold,
  walletBold,
  chartBold,
  passwordBold,
  trashBold,
  lockKeyholeUnlockedBold,
  widgetBold,
  qrCodeBold,
  addCircleBold,
  arrowRightDownBold,
  arrowRightUpBold,
  busBold,
  userBold,
  mapArrowRotateBold,
  radarBold,
  headPhonesBold,
  garageBold,
  globusBold,
  pointOnMapPerspectiveBold,
  cityBold,
  layerBold,
  threeSquaresBold,
  serverMinimalisticBold,
  crossBold,
  userHeartBold,
  rulerCrossPenBold,
  arrowSquareBold,
  userIdBold,
  devicesBold,
  suspensionBold,
  removeFileBold,
  fileBold,
  fileRightBold,
  fileLoadBold,

])
Vue.use(HeroIcon)

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$domain = domain;
Vue.prototype.$phoneCodes = phoneCodes;
Vue.prototype.$countries = countries;
Vue.prototype.$hasFlag = hasFlag;
Vue.prototype.$getUnicodeFlagIcon = getUnicodeFlagIcon;
Vue.prototype.$rule = rules;

const token = localStorage.getItem("token");
if (token) {
  http.defaults.headers.common["Authorization"] = "Bearer " + token;
}

Vue.config.productionTip = false;
Vue.prototype.$http = http;

window.dbg = (data) => {
  console.debug(data)
  return data;
}

window.tbl = (data) => {
  console.table(JSON.parse(JSON.stringify(data)))
  return data;
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount("#app");
