import Vue from 'vue';
import Vuex from 'vuex';
import pages from './api/pages'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    windowWidth: 0,
    locale: localStorage.getItem('locale') || 'ru',
    globalErrorMessage: {},
    currentParcel: null,
    passwordRestore: null,
    selectedVehicle: null,
    status: '',
    token: localStorage.getItem('token') || '',
    user: {
      roles: [],
    },
    hasAny: perm => false,
    loading: false,
    refreshPageDataCallback: null,
    defaultRefreshPageDataCallback: () => {
      window.location.reload();
      history.go(0);
    },
    errorMessages: [],
    menu: []
  },
  mutations: {
    set_current_parcel(state, payload) {
      state.currentParcel = payload;
    },
    set_current_vehicle(state, payload) {
      state.selectedVehicle = payload;
    },
    window_width(state, payload) {
      state.windowWidth = payload;
    },
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, payload) {
      state.status = 'success';
      state.token = payload;
    },
    set_user(state, payload) {
      state.user = payload;
      state.hasAny = permission => {
        let r = false;
        if (state.user && state.user.roles) {
          r = state.user.roles.filter(role => role.active && role.permissions.filter(p => p.name.value === permission || p.name.value === 'ADMIN').length > 0).length > 0
        }
        console.debug('hasAny', permission, r)
        return r;
      }
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
      state.user = {};
    },
    set_loading(state, payload) {
      state.loading = payload;
    },
    set_global_error_message(state, val) {
      state.globalErrorMessage = val
    },
    set_menu(state, val) {
      state.menu = val
    },
    add_error_messages(state, message) {
      state.errorMessages.push({message: message, color: 'rgba(35,40,51,0.93)'})
    },
    set_password_restore(state, payload) {
      state.passwordRestore = payload;
    },
    add_success_messages(state, message) {
      state.errorMessages.push({message: message, color: 'rgba(76,175,80,0.93)'})
      if (!!state.errorMessages && Array.isArray(state.errorMessages) && state.errorMessages.length > 0) {
        setTimeout(() => {
          state.errorMessages = state.errorMessages.slice(0,-1)
        }, 3000);
      }
    }
  },
  actions: {
    setPasswordRestore(context, val) {
      context.commit('set_password_restore', val);
    },
    setCurrentVehicle(context, val) {
      context.commit('set_current_vehicle', val);
    },
    setCurrentParcel(context, val) {
      context.commit('set_current_parcel', val);
    },
    setWindowWidth(context, val) {
      context.commit('window_width', val);
    },

    setGlobalErrorMessage(context, message) {
      if (message === undefined) {
        context.commit('set_global_error_message', {
          message: 'Упс... Что-то пошло не так',
          error: null
        });
      } else if (message instanceof Error) {
        console.error(message)
        context.commit('set_global_error_message', {
          message: 'Упс... Что-то пошло не так',
          error: message
        });
      } else {
        context.commit('set_global_error_message', {
          message: message,
          error: null
        });
      }
    },

    setLoading: function (context, value) {
      context.commit('set_loading', value);
    },
    setLanguage: function (context, value) {
      localStorage.removeItem('locale');
      localStorage.setItem('locale', value);
    },
    init(context) {
      const token = localStorage.getItem('token');
      if (token) {
        Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.commit('auth_success', token);

        Vue.prototype.$http.get('/auth/current')
          .then(value => this.commit('set_user', value.data))
      }
    },

    login({commit, dispatch}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        Vue.prototype.$http.post('/public/auth/login', user)
          .then(resp => {
              if (resp.data.token) {
                localStorage.setItem('token', resp.data.token);
                dispatch('init');

                resolve(resp);
              } else {
                console.log(resp.data.message)
                reject(resp.data.message || 'Ошибка! Обратитесь к администратору.');
              }
            },
            err => {
              this.commit('auth_error');
              console.log(err.response?.data?.message)
              localStorage.removeItem('token');
              reject(err.response?.data?.message);
            });
      });
    },
    get_menu(context) {
      let menu = [];
      return new Promise((resolve, reject) => {
        Vue.prototype.$http.get('/public/availableContent')
          .then(resp => {
              if (resp.data.length > 0) {
                let r = resp.data;
                for (let i in r) {
                  let page = r[i]
                  if (pages.has(page.value)) {
                    menu.push(
                      Object.assign(pages.get(page.value), page)
                    )
                  }
                }
                context.commit('set_menu', menu);
                resolve(resp);
              } else {
                reject(resp.data.message || 'Ошибка! Обратитесь к администратору.');
              }
            },
            err => {
              reject(err.response?.data?.message);
            });
      });
    },
    logout({commit}) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        delete Vue.prototype.$http.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    addErrorMessages(context, message) {
      context.commit('add_error_messages', message);
    },
    addSuccessMessages(context, message) {
      context.commit('add_success_messages', message);
    },
  },
  getters: {
    windowWidth: state => state.windowWidth,
    modalWidth: state => {
      let dw = (700 / state.windowWidth) * 90;
      if (state.windowWidth > 1280) return '30vw';
      else if (state.windowWidth < 700) return '90vw';
      else return Math.round(dw) + 'vw';
    },
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    currentParcel: state => state.currentParcel,
    user: state => state.user,
    username: state => state.user.email,
    userFullName: state => state.user.fullName,
    loading: state => state.loading,
    globalErrorMessage: state => state.globalErrorMessage,
    globalErrorDialog: state => state.globalErrorMessage && state.globalErrorMessage.message,
    hasAny: state => state.hasAny,
    allRoles: state => state.user.roles || [],
    activeRole: state => (state.user.roles || []).filter(role => role.active).values().next().value,
    errorMessages: state => state.errorMessages,
    menu: state => state.menu,
    selectedVehicle: state => state.selectedVehicle,
    passwordRestore: state => state.passwordRestore,
    locale: state => state.locale,
  }
});
