import http from "../plugins/http";
import store from "../store";

export default [
    {
        path: "*",
        meta: {
            public: true
        },
        redirect: {
            path: "/404"
        }
    },
    {
        path: "/404",
        meta: {
            public: true
        },
        name: "NotFound",
        component: () => import(`@/views/error/NotFound.vue`)
    },
    {
        path: "/403",
        meta: {public: true},
        name: "AccessDenied",
        component: () => import(`@/views/error/Deny.vue`)
    },
    {
        path: "/500",
        meta: {public: true},
        name: "ServerError",
        component: () => import(`@/views/error/Error.vue`)
    },
    {
        path: "/",
        meta: {},
        name: "Root",
        redirect: {
            name: "Home"
        }
    },
    {
        path: "/",
        meta: {public: true},
        name: "Home",
        component: () => import(`@/views/Home.vue`)
    },
    {
        path: "/vehicles",
        meta: {userInfo: true},
        name: "vehicles",
        component: () => import(`@/views/Vehicles.vue`)
    },
    {
        path: "/register",
        meta: {public: true},
        name: "register",
        component: () => import(`@/views/Register.vue`)
    },
    // {
    //     path: "/add-vehicles",
    //     meta: {userInfo: true},
    //     name: "add-vehicles",
    //     component: () => import(`@/views/AddVehicles/index.vue`)
    // },
    {
        path: "/add-vehicle/:type/:id",
        meta: {userInfo: true},
        name: "classic",
        component: () => import(`@/views/AddVehicles/TypeVehicles.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.type && !!routeTo.params.id) {
                http.get(`/team/check/link?link=${routeTo.params.type}`)
                    .then(r => {
                            next()
                        },
                        () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-vehicles/technical-data/:id",
        meta: {userInfo: true},
        name: "technical-data",
        component: () => import(`@/views/AddVehicles/TechnicalData.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.id) {
                http.get(`/vehicles/byId?id=${routeTo.params.id}`)
                    .then(r => {
                            store.dispatch('setCurrentVehicle', r.data)
                            next()
                        }, () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-vehicles/sports-documents/:id",
        meta: {userInfo: true},
        name: "sports-documents",
        component: () => import(`@/views/AddVehicles/SportsDocuments.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.id) {
                http.get(`/vehicles/byId?id=${routeTo.params.id}`)
                    .then(r => {
                            store.dispatch('setCurrentVehicle', r.data)
                            next()
                        }, () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-vehicles/passport-data/:id",
        meta: {userInfo: true},
        name: "passport-data",
        component: () => import(`@/views/AddVehicles/PassportData.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.id) {
                http.get(`/vehicles/byId?id=${routeTo.params.id}`)
                    .then(r => {
                            store.dispatch('setCurrentVehicle', r.data)
                            next()
                        }, () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-vehicles/vehicle-photos/:id",
        meta: {userInfo: true},
        name: "vehicle-photos",
        component: () => import(`@/views/AddVehicles/VehiclePhotos.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.id) {
                http.get(`/vehicles/byId?id=${routeTo.params.id}`)
                    .then(r => {
                            store.dispatch('setCurrentVehicle', r.data)
                            next()
                        }, () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-vehicles/trailer-data/:id",
        meta: {userInfo: true},
        name: "trailer-data",
        component: () => import(`@/views/AddVehicles/TrailerData.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.id) {
                http.get(`/vehicles/byId?id=${routeTo.params.id}`)
                    .then(r => {
                            store.dispatch('setCurrentVehicle', r.data)
                            next()
                        }, () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))

            }
        },
    },
    {
        path: "/add-team",
        meta: {userInfo: true},
        name: "add-team",
        component: () => import(`@/views/CreateTeam.vue`)
    },
    {
        path: "/media-documents",
        meta: {userInfo: true},
        name: "media-documents",
        component: () => import(`@/views/MediaDocuments.vue`)
    },
    {
        path: "/password/restore/:secret",
        meta: {public: true},
        name: "restorePassword",
        component: () => import(`@/views/RestorePassword.vue`),
        beforeEnter(routeTo, _routeFrom, next) {
            if (!!routeTo.params.secret) {
                http.get(`/verify/password/restore?secret=${routeTo.params.secret}`)
                    .then(r => {
                            next()
                            store.dispatch('setPasswordRestore', {
                                user: r.data,
                                secret: routeTo.params.secret
                            })
                        },
                        () => next({name: 'NotFound'})
                    )
                    .catch(() => next({name: 'NotFound'}))
            } else {
                next({name: 'NotFound'})
            }
        },
    },
    {
        path: "/accounting/:id",
        meta: {public:true},
        name: "accounting",
        component: () => import(`@/views/Accounting/index.vue`)
    },

];
