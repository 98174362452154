<template>
  <v-dialog persistent max-width="300px" v-model="dialog">
    <v-card>
      <v-card-title>
        <v-spacer/>
        {{ $t('account.warning_popup_window_label') }}
        <v-spacer/>
      </v-card-title>
      <v-card-actions class="pb-4">
        <v-spacer/>
        <v-btn :disabled="loading" @click="close" color="primary" dense>{{ $t('account.warning_popup_window_button_cancel') }}</v-btn>
        <v-btn :loading="loading" @click="confirm" color="primary" outlined dense>{{ $t('account.warning_popup_window_button_confirm') }}</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: Function,
  },
  computed: {
    dialog: {
      get() {
        return !!this.value;
      },
      set(val) {
        if (!val) this.$emit('input')
      }
    }
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.dialog = false;
      this.loading = false;
    },
    confirm() {
      this.loading = true;
      this.value().finally(this.close)
    }
  }
}
</script>

<style scoped>

</style>