<template>
  <v-app-bar app outlined height="74" style="background-color: white;">
    <div class="page-wrapper d-flex justify-space-between"
         style="display: flex; align-items: center; width: 1200px; padding: 0 !important;">
      <div>
        <v-img src="/static/images/logo.svg" height="74px" contain @click="$router.push('/vehicles')"/>
      </div>
      <div v-if="!!user?.type && user.type.value === 'MEDIA'" class="ml-8" @click="$router.push('/media-documents')">
        <v-btn text>
          {{ $t('account.vehicles_tab_docs_label') }}
        </v-btn>
      </div>
      <!--      <div class="ml-8" @click="$router.push('/accounting')" v-if="!!user?.type">-->
      <!--        <v-btn text>-->
      <!--          Бухгалтерия-->
      <!--        </v-btn>-->
      <!--      </div>-->
      <v-spacer/>
      <v-btn text class="mr-4" @click="logout" v-if="isLoggedIn">{{ $t('account.main_button_logout') }}</v-btn>
      <div class="d-flex align-center" v-if="isLoggedIn">
        <div class="mr-2 d-flex flex-column align-end justify-center">
          {{ user.email }}<br/>
          <!--          <p style="color: #bebebe" v-if="!!user?.type">{{user.type.description}}</p>-->
        </div>
        <v-img :src="'data:image/jpeg;base64, ' + user.avatar" height="50px" width="50px" aspect-ratio="1" class="mx-2"
               style="border-radius: 50%"/>
      </div>
      <v-dialog v-model="dialog" width="400px">
        <v-card>
          <v-card-text class="pt-8">
            При обращении в техническую поддержку, сообщите следующие данные: <br/>
            - адрес электронной почты, под которым осуществляется регистрация;<br/>
            - описание проблемы: какая проблема возникла и на каком этапе регистрации;<br/>
            - (опционально) снимки экрана с ошибкой;<br/>
            - контакты для связи;<br/>
            <v-card-actions class="d-flex pa-0 ma-0 mt-4 justify-center">
              <v-btn color="primary" text @click="dialog = false">ok</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="d-flex align-center" @click="dialog = true" style="cursor: pointer;">
        <div>
          <v-img src="/static/images/whatsapp.png" width="40px"/>
        </div>
        <div class="ml-2" style="font-size: 12px">
          +7 (916) 608-61-18<br/>
          <p style="font-size: 10px; color: gray">{{ $t('main.support_contact') }}</p>
        </div>
      </div>
      <div style="width: 70px" class="ml-4">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text class="pa-0" small>
              <v-img :src="getLocaleImage" width="60"/>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in langs"
                :key="index"
                @click="changeLocale(item.value)"
            >
              <v-img :src="item.icon" width="30"/>
              <v-list-item-title v-html="item.name" class="ml-2"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
<!--      <div>-->
<!--        <v-btn @click="changeLocale('en')">en</v-btn>-->
<!--      </div>-->
<!--      <div>-->
<!--        <v-btn @click="changeLocale('ru')">ru</v-btn>-->
<!--      </div>-->
    </div>
  </v-app-bar>
</template>
<script>
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
import i18n from "../plugins/vue-i18n";

export default {
  name: "AppToolbar",
  components: {},
  data() {
    return {
      competitions: [],
      dialog: false,
      langs: [
        {name: "Русский", value: "ru", icon: "/static/images/ru.png"},
        {name: "English", value: "en", icon: "/static/images/en.png"},
      ]
    };
  },
  computed: {
    ...mapGetters(['user', 'isLoggedIn', 'locale']),
    getLocaleImage() {
      if (this.$i18n.locale === 'ru') return "/static/images/ru.png"
      else return "/static/images/en.png"
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    i18n() {
      return i18n
    },
    ...mapActions(['logout']),
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/")
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
    },
  },
  created() {
    if (!!this.user?.id) {
      this.$http.get('/public/competition')
          .then(r => this.competitions = r.data)
    }
  }
};
</script>