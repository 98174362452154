<template>
  <v-footer height="auto" class="bg pa-3">
    <div class="page-wrapper py-8">
      <v-row >
        <v-col cols="2">
          <v-btn text class="mb-2">Cost of services</v-btn>
          <v-btn text class="mb-2">Privacy Policy</v-btn>
          <v-btn text class="mb-2">Public offer</v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn text class="mb-2">Delivery calculator</v-btn>
          <v-btn text class="mb-2">Support</v-btn>
        </v-col>
        <v-col cols="8" class="py-4 d-flex justify-end">
        <span class="caption" style="color:#EE1D23;"> &copy; {{ new Date().getFullYear() }} Parcelpoint LLC</span>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "app-fab",

  data: () => ({
    fab: false
  }),

  methods: {
    onScroll() {
      if (typeof window === "undefined") return;

      const top = window.pageYOffset || document.documentElement.offsetTop || 0;

      this.fab = top > 300;
    },
    toTop() {
      this.$router.push({ hash: "" });
      this.$vuetify.goTo(0);
    }
  }
};
</script>
