<template>
  <div id="appRoot" v-on:resize="getWidth">
    <template v-if="$route.meta.userInfo && !loading">
      <v-app id="inspire" class="app">
        <app-toolbar/>
        <v-main class="my-8">
          <v-row class="pa-0 page-wrapper">
            <v-col cols="12" class="pa-0 pt-2">
              <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="$route.meta.public && !loading">
      <v-app id="inspire" class="app">
        <app-toolbar/>
        <v-main class="my-8 px-10">
          <v-row class="pa-0 page-wrapper">
            <v-col cols="12" class="pa-0 pl-4">
              <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="!loading">
      <v-app-bar app flat outlined height="74">
        <div class="page-wrapper d-flex" style="display: flex; align-items: center">
          <v-spacer v-if="currentRouteName === 'Signup' || currentRouteName === 'Login'"/>
          <div @click="$router.push('/home')">
            <v-img src="/static/images/logo.svg" height="74px" contain/>
          </div>
          <v-spacer/>
          <v-btn @click="$router.push('login')" text
                 v-if="currentRouteName !== 'Signup' && currentRouteName !== 'Login'">Log in</v-btn>
        </div>
      </v-app-bar>
      <v-app class="primary">
        <keep-alive class="page-wrapper">
          <router-view :key="$route.fullpath"/>
        </keep-alive>
      </v-app>
    </template>
    <loading v-if="loading"/>
    <error-dialog :global-error-dialog="globalErrorDialog"
                  :global-error-message="globalErrorMessage"
                  @click="setGlobalErrorMessage($event)"/>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import AppFab from "@/components/AppFab";
import AppEvents from "./event";
import Loading from "@/views/Loading";

export default {
  components: {
    Loading,
    AppDrawer,
    AppToolbar,
    AppFab
  },
  data: () => ({
    width: 0,
    expanded: true,
    rightDrawer: false,
    showDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    }
  }),
  computed: {
    ...mapGetters(['loading', 'windowWidth', 'modalWidth', 'globalErrorMessage', 'globalErrorDialog','errorMessages']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    }
  },
  methods: {
    ...mapActions(['init', 'setWindowWidth', 'setGlobalErrorMessage']),
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },
  },
  created() {
    this.init();
    this.getWidth();

    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'shortcut icon';
    link.href = '/favicon.png';
    document.getElementsByTagName('head')[0].appendChild(link);

    this.width = document.body.clientWidth;
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;

    // this.$http.interceptors.response.use(
    //     response => {
    //       return response;
    //     },
    //     err => {
    //       return new Promise((resolve, reject) => {
    //         if (err?.response?.status === 401 || err?.response?.status === 403) {
    //           this.$store.dispatch("logout");
    //           this.$router.push("/");
    //         } else {
    //           reject(err);
    //         }
    //       });
    //     }
    // );
  }
};
</script>

<style lang="stylus" scoped>
@import 'assets/fonts.css';
a:active, a:focus { outline: none; }

textField, textarea {outline:none;}
textField:active, textarea:active {outline:none;}
:focus {outline:none;}
textarea {resize:none;}
textarea {resize:vertical;}
textarea {resize:horizontal;}
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.bg {
  background-color: #fafafa;
}
.container {
  padding: 15px !important;
}

textarea:focus, input:focus{
  outline: none;
}
</style>
